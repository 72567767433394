import { Client as Appwrite, Databases, Account } from "appwrite";
import { Server } from "../Utils/config";
import axios from "axios";

/**
 * (AbortSignal, Date) -> Promise Boolean
 * @param abortionSignal AbortSignal
 * @param date Date
 * @return Promise Boolean
 */
export async function checkLunchAvailability(abortionSignal, date) {

    const url = `${Server.backendURL}/api/2.6/dates/${date.toISODate()}/lunch-available`;

    const response = await fetch(url, {
        signal: abortionSignal
    });

    return await response.json();

};

export function createTeamUitjeRequest(teamuitje) {
    return axios.post(`${Server.backendURL}/api/2.0/teamuitje-requests`, teamuitje, {
        headers: {
            'content-type': 'application/json'
        }
    });
}

export function createAanpakkerRequest(aanpakker) {
    return axios.post(`${Server.backendURL}/api/3.1/aanpakker-voor-een-dag-requests`, aanpakker, {
        headers: {
            'content-type': 'application/json'
        }
    });
}

export function createHeldenAvondRequest(aanpakker) {
    return axios.post(`${Server.backendURL}/api/2.0/heldenavond-requests`, aanpakker, {
        headers: {
            'content-type': 'application/json'
        }
    });
}
