import { useState } from "react"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

/**
 * Properties -> React.ReactNode
 * CompanyInformation = {
 *  city            : String
 *  companyName     : String
 *  department      : String
 *  streetAndNumber : String
 *  zipcode         : String
 * }
 * Properties = {
 *  companyInformation      : Void | Object CompanyInformation
 *  invalid                 : Boolean
 *  required                : Boolean
 *  setCompanyInformation   : CompanyInformation -> Any
 * }
 * @param properties Properties
 * @return React.ReactNode
 */
export default function CompanyInformationFields(properties) {

    const { companyInformation = {} }   = properties
    const { invalid }                   = properties
    const { required }                  = properties
    const { setCompanyInformation }     = properties

    const changeCompanyInformation = (e) => {
        setCompanyInformation({...companyInformation, [`${e.target.name}`]: e.target.value});
    }

    return (
        <div className="mx-2">

            <div className="lg:flex lg:flex-wrap lg:gap-x-8 lg:w-full">
                <p className="w-full font-proxima font-bold block mb-4">Bedrijfsgegevens</p>

                <div className="mb-6 lg:w-1/3">
                    <input
                        type="text"
                        id="companyName"
                        name="companyName"
                        value={companyInformation.companyName}
                        onChange={e => changeCompanyInformation(e)}
                        required={required}
                        className={`${invalid ? "peer/companyName invalid:border-red-400 invalid:bg-red-200 invalid:text-red-500" : ''} w-full rounded-xl border-2 border-grey px-4 py-2 font-proxima`}

                        placeholder={[
                            'Bedrijfsnaam',
                            required ? '*' : null
                        ]
                        .filter(value => value != null)
                        .join(' ')}

                    />
                    <label htmlFor="companyName" className="font-proxima text-xs text-center sr-only">Bedrijfsnaam</label>
                    <span className="hidden peer-invalid/companyName:block w-full font-proxima text-red-500 rounded-xl mt-2">Er is geen bedrijfsnaam ingevuld.</span>
                </div>

                <div className="mb-6 lg:w-1/3">
                    <input
                        type="text"
                        id="department"
                        name="department"
                        value={companyInformation.department}
                        placeholder="Afdeling"
                        onChange={e => changeCompanyInformation(e)}
                        className={`${invalid ? "peer/department invalid:border-red-400 invalid:bg-red-200 invalid:text-red-500" : ''} w-full rounded-xl border-2 border-grey px-4 py-2 font-proxima`}
                    />
                    <label htmlFor="department" className="font-proxima text-xs text-center sr-only">Afdeling</label>
                    <span className="hidden peer-invalid/department:block w-full font-proxima text-red-500 rounded-xl mt-2">Er is geen afdeling ingevuld.</span>
                </div>

                <div className="mb-6 lg:w-1/3">
                    <input
                        type="text"
                        id="streetAndNumber"
                        name="streetAndNumber"
                        value={companyInformation.streetAndNumber}
                        onChange={e => changeCompanyInformation(e)}
                        required={required}
                        className={`${invalid ? "peer/streetAndNumber invalid:border-red-400 invalid:bg-red-200 invalid:text-red-500" : ''} w-full rounded-xl border-2 border-grey px-4 py-2 font-proxima`}

                        placeholder={[
                            'Straatnaam + huisnummer',
                            required ? '*' : null
                        ]
                        .filter(value => value != null)
                        .join(' ')}

                    />
                    <label htmlFor="streetAndNumber" className="font-proxima text-xs text-center sr-only">Straatnaam + huisnummer</label>
                    <span className="hidden peer-invalid/streetAndNumber:block w-full font-proxima text-red-500 rounded-xl mt-2">Er is geen straatnaam + huisnummer ingevuld.</span>
                </div>

                <div className="mb-6 lg:w-1/3">
                    <input
                        type="text"
                        id="zipcode"
                        name="zipcode"
                        value={companyInformation.zipcode}
                        onChange={e => changeCompanyInformation(e)}
                        pattern="[1-9][0-9]{3}\s?[a-zA-Z]{2}"
                        required={required}
                        className={`${invalid ? "peer/zipcode invalid:border-red-400 invalid:bg-red-200 invalid:text-red-500" : ''} w-full rounded-xl border-2 border-grey px-4 py-2 font-proxima`}

                        placeholder={[
                            'Postcode',
                            required ? '*' : null
                        ]
                        .filter(value => value != null)
                        .join(' ')}

                    />
                    <label htmlFor="zipcode" className="font-proxima text-xs text-center sr-only">Postcode</label>
                    <span className="hidden peer-invalid/zipcode:block w-full font-proxima text-red-500 rounded-xl mt-2">Er is geen postcode ingevuld.</span>
                </div>

                <div className="mb-6 lg:w-1/3">
                    <input
                        type="text"
                        id="city"
                        name="city"
                        value={companyInformation.city}
                        onChange={e => changeCompanyInformation(e)}
                        required={required}
                        className={`${invalid ? "peer/city invalid:border-red-400 invalid:bg-red-200 invalid:text-red-500" : ''} w-full rounded-xl border-2 border-grey px-4 py-2 font-proxima`}

                        placeholder={[
                            'Plaatsnaam',
                            required ? '*' : null
                        ]
                        .filter(value => value != null)
                        .join(' ')}

                    />
                    <label htmlFor="city" className="font-proxima text-xs text-center sr-only">Plaatsnaam</label>
                    <span className="hidden peer-invalid/city:block w-full font-proxima text-red-500 rounded-xl mt-2">Er is geen plaatsnaam ingevuld.</span>
                </div>

            </div>

        </div>

    )



}
