import { useState, useEffect, useMemo } from "react";
import SelectDate from "../Components/SelectDate";
import Calendar from "../Components/Calendar";
import DayCard from "../Components/DayCard";
import DayDetails from "../Components/DayDetails";
import CalendarLegend from "../Components/CalendarLegend";
import Preferences from "../Components/Preferences";
import Information from "../Components/Information";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import NumberOfPeople from "../Components/NumberOfPeople";
import { useCalendarEventContext } from "../Context/CalendarEventContext";
import { createAanpakkerRequest } from "../api/backend";
import { DateTime } from "luxon";
import LoadingOverlay from "../Components/LoadingOverlay";
import { usePublicSettingsContext } from "../Context/PublicSettingsContext";
import { formatDateFromISO, formatDate } from "../Utils/date";
import useAvailableCustomEvents from "../Hooks/useAvailableCustomEvents";


export default function AanpakkerVoorEenDagSignup(properties) {

    const { page } = properties;

    const [privacyAgreed, setPrivacyAgreed] = useState(false);
    const [selectedDay, setSelectedDay] = useState(null);
    const [visitorCount, setVisitorCount] = useState(1);
    const [message, setMessage] = useState('');
    const [visitorLimit, setVisitorLimit] = useState(10);
    const [visitorInformation, setVisitorInformation] = useState({
        0: {
            firstName: '',
            lastName: '',
            emailAddress: '',
            phoneNumber: '',
            birthdate: '',
        }
    });
    const [companyInformation, setCompanyInformation] = useState({
        companyName: '',
        department: '',
        streetAndNumber: '',
        zipcode: '',
        city: '',
    });

    // Form
    const [invalid, setInvalid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState('');

    const formData = {
        date: selectedDay ? DateTime.fromFormat(selectedDay, 'dd-MM-yyyy').toISODate() : "",
        participants: Object.values(visitorInformation).map(item => {
            const obj = Object.assign({}, item);
            obj['birthdate'] = obj['birthdate'] !== "" ? DateTime.fromJSDate(obj['birthdate']).toISODate() : "";
            return obj;
        }),
        organizationAddress: companyInformation.streetAndNumber,
        organizationCity: companyInformation.city,
        organizationName: companyInformation.companyName,
        organizationPostalCode: companyInformation.zipcode,
        department: companyInformation.department,
        comments: message
    }


    const handleSubmitForm = async (e) => {

        e.preventDefault();

        if (loading)
            return;

        setLoading(true);
        setNotifications([]);

        let hasError = false;

        if(!privacyAgreed) {
            setNotifications(prevState => ([...prevState, "privacyAgreed"]))
            hasError = true;
        }

        if (!selectedDay) {
            setNotifications(prevState => ([...prevState, "selectedDay"]))
            hasError = true;
        }

        if (!e.target.checkValidity()) {
            setNotifications(prevState => ([...prevState, "formfields"]))
            hasError = true;
        }

        let participants = formData.participants;
        let underagedParticipants = participants.filter(participant => {
            let participantBirthdate = DateTime.fromISO(participant.birthdate);
            let eventDate = DateTime.fromISO(formData.date);
            let ageOnEventDate = eventDate.diff(participantBirthdate, 'years').toObject().years;
            return ageOnEventDate < 18;
        })

        if (underagedParticipants.length > 0) {
            setNotifications(prevState => ([...prevState, "underagedParticipants"]))
            hasError = true;
        }

        if (hasError) {
            setInvalid(true);
            setLoading(false);
            return null;
        }

        const request = await createAanpakkerRequest(formData);

        if ([200, 201, 204].includes(request.status)) {
            window.location.href = 'https://stichtingjarigejob.nl/aanpakker-bedankt/';
            return;
        }


        setLoading(false);

    }

    return (
        <div>
            <div className="flex flex-col h-full">
                <div className="lg:w-[70vw] p-2 lg:p-5 lg:mx-auto">
                    <h1 className="font-proxima font-black text-2xl lg:text-4xl text-cal-blue">Inschrijven aanpakker voor een dag</h1>
                </div>
                <div className="lg:w-[70vw] mx-auto lg:p-5">
                    <form
                        onSubmit={handleSubmitForm}
                        className={`bg-[#FAFAFA] ${invalid ? "display-errors" : ""}`}
                        noValidate
                    >
                        <SelectDate page={page} />
                        <CalendarLegend />
                        <ClientCalendar
                            selectedDay={selectedDay}
                            setSelectedDay={setSelectedDay}
                            setVisitorLimit={setVisitorLimit}
                            visitorCount={visitorCount}
                            setVisitorCount={setVisitorCount}
                            page={page}
                        />
                        <NumberOfPeople
                            selectedDate={selectedDay}
                            visitorCount={visitorCount}
                            setVisitorCount={setVisitorCount}
                            visitorInformation={visitorInformation}
                            setVisitorInformation={setVisitorInformation}
                            visitorLimit={visitorLimit}
                        />
                        <Information
                            visitorCount={visitorCount}
                            visitorInformation={visitorInformation}
                            setVisitorInformation={setVisitorInformation}
                            companyInformation={companyInformation}
                            setCompanyInformation={setCompanyInformation}
                            message={message}
                            setMessage={setMessage}
                            page={page}
                            notifications={notifications}
                            invalid={invalid}
                            privacyAgreed={privacyAgreed}
                            setPrivacyAgreed={setPrivacyAgreed}
                        />
                    </form>
                </div>
            </div>
        </div>
    );
}

function ClientCalendar(properties) {

    const {
        selectedDay,
        setSelectedDay,
        setVisitorLimit,
        visitorCount,
        setVisitorCount,
        page
    } = properties;

    const {
        unavailableDates,
        calendarEvents,
        aanpakkerLoading,
        unavailableDateLoading,
    } = useCalendarEventContext();

    const { publicSettings } = usePublicSettingsContext();

    const availableCustomEvents = useAvailableCustomEvents();

    const calendarAvailableTill = formatDateFromISO(publicSettings?.aanpakkerVoorEenDagCalendarEndDateExclusiveUpperBound || "2024-10-01T00:00:000.Z");

    const dataLoading = aanpakkerLoading || unavailableDateLoading;

    const selectDay = (date) => {

        setSelectedDay((prevDate) => prevDate == date ? null : date);
        let unavailabilityObject = (unavailableDates || []).find(x => x.date == date);
        let maxPerDay = 10;
        if(unavailabilityObject?.aanpakkerMaximumAmountOfParticipants > 0) {
            maxPerDay = unavailabilityObject.aanpakkerMaximumAmountOfParticipants;
        }

        let initialCount = 0;

        if (typeof (calendarEvents[date]) !== "undefined") {

            let events = Object.values(calendarEvents[date])
                .filter((event) => {
                    if (event.$$type !== "aanpakker") return false;
                    if (event.amountOfParticipants == null) return false;
                    return true;
                })
                .reduce((event, currentValue) => {

                    return (
                        event + currentValue.amountOfParticipants
                    )

                }, initialCount);

            let spotsLeft = maxPerDay - events;

            setVisitorLimit(spotsLeft);

            if (spotsLeft < visitorCount) setVisitorCount(1);

            return;

        }

        setVisitorLimit(maxPerDay);

    }

    let dayCards = {}

     // Als een datum niet beschikbaar is
    if (unavailableDates) { 
        Object.values(unavailableDates).map(x => {

            if(x.aanpakkerMaximumAmountOfParticipants > 0) {
                return;
            }


            dayCards[x.date] = {
                dayLabelClass: 'text-black',
                clickable: false,
                component: <DayCard>
                    <div className="p-2 bg-cal-lighter-gray text-black w-full h-full flex items-center">
                        <p className="invisible lg:visible text-left">Niet beschikbaar</p>
                    </div>
                </DayCard>
            } 
        }) 
    }

    if(availableCustomEvents) {
        Object.entries(availableCustomEvents).map(([date, item]) => {
            dayCards[date] = {
                dayLabelClass: 'text-black',
                clickable: false,
                component: <DayCard>
                    <div className="p-2 bg-cal-lighter-gray text-black w-full h-full flex items-center">
                        <p className="invisible lg:visible text-left">Niet beschikbaar</p>
                    </div>
                </DayCard>
            } 
        });
    }

    Object.keys(calendarEvents).map((date) => {
        
        if(dayCards[date]) return;

        let unavailabilityObject = (unavailableDates || []).find(x => x.date == date);
        let maxPerDay = 10;
        if(unavailabilityObject?.aanpakkerMaximumAmountOfParticipants > 0) {
            maxPerDay = unavailabilityObject.aanpakkerMaximumAmountOfParticipants;
        }
        
       let initialCount = 0;

        // Filter alle events er uit die geen "Aanpakker voor een dag" zijn
        let events = Object.values(calendarEvents[date])
            .filter((event) => {
                if (event.$$type !== "aanpakker") return false;
                if (event.amountOfParticipants == null) return false;
                return true;
            })
            .reduce((event, currentValue) => {

                return (
                    event + currentValue.amountOfParticipants
                )

            }, initialCount);

        if (events == 0) return;

        if (events >= maxPerDay) {
            dayCards[date] = {
                clickable: false,
                dayLabelClass: 'text-white',
                component: <DayCard>
                    <div className="p-2 bg-cal-lighter-gray w-full h-full flex items-center">
                        <p className="text-left ">Niet beschikbaar</p>
                    </div>
                </DayCard>
            }
            return;
        }

        let spotsLeft = maxPerDay - events;

        dayCards[date] = {
            dayLabelClass: 'text-black',
            component: <DayCard>
                <div className="w-full h-full flex flex-col justify-center p-2">
                    <ul className="flex flex-col text-left gap-1">
                        <li className="text-xs flex flex-row gap-2 items-center">
                            <div className={`w-2 h-2 aspect-square bg-red-400`}></div>
                            Nog {spotsLeft} {spotsLeft == 1 ? "plek" : "plekken"} beschikbaar
                        </li>
                    </ul>
                </div>
            </DayCard>
        }

    })

    if (selectedDay) {
        dayCards[selectedDay] = {
            dayLabelClass: 'text-white',
            component: <DayCard>
                <div className="bg-cal-blue text-white w-full h-full flex justify-center items-center gap-2">
                    <CheckCircleIcon className="w-8 text-white" /> <span className="hidden lg:block font-proxima">Geselecteerde datum</span>
                </div>
            </DayCard>,
        }
    }

    const PassedDayCard = <DayCard>
        <div className="p-2 bg-cal-lighter-gray w-full h-full flex items-center">
            <p className="text-left ">Niet beschikbaar</p>
        </div>
    </DayCard>

    const NotAvailableYetDayCard = <DayCard>
        <div className="p-2 bg-cal-lighter-gray w-full h-full flex items-center">
            <p className="text-left ">(Nog) niet beschikbaar</p>
        </div>
    </DayCard>

    return <div className="flex flex-col gap-2 items-center">
        <div className="relative w-full">
            <LoadingOverlay loading={dataLoading} />
            <Calendar
                calendarAvailableTill={calendarAvailableTill}
                dayCards={dayCards}
                NotAvailableYetDayCard={NotAvailableYetDayCard}
                onDayClick={selectDay}
                page={page}
                passedDayCard={PassedDayCard}
            />
        </div>
        <div>
            Jouw geselecteerde datum: {selectedDay == null
            ? 'kies een beschikbare datum'
            : <span className="font-bold">{DateTime
                .fromFormat(selectedDay, 'dd-MM-yyyy')
                .setLocale('nl-NL')
                .toLocaleString(DateTime.DATE_HUGE)}</span>}
        </div>
    </div>

}
